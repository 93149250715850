import React from 'react';
import logo from './logo.svg';
import './App.css';
import pdfFile from "./static/Jack Manley Resume.pdf";

function App() {
    return (
        <div className="App">
            <div className="content">
                <div style={{fontSize: 30}}>Jack Manley</div>
                <div style={{paddingTop: 10} } >Full stack software developer with a passion for creating great
                    user experiences on desktop and mobile platforms.
                </div>
                <div style={{ paddingTop: 5 }} >
                    <a href={pdfFile} >Resume</a>
                </div>
                <div style={{ paddingTop: 5 }}>
                    <a  href="mailto:jackmanleydev@gmail.com">jackmanleydev@gmail.com</a>
                </div>
            </div>
        </div>
    );
}

// <img src={logo} className="App-logo" alt="logo" />
/*
  <header className="App-header">
                <div>Jack Manley</div>
                <div>About | Resume | Contact</div>
            </header>
            <section className="App-section">
                
                This is section 1
            </section>
            <section className="App-section">
                
                This is section 2
            </section>
            <section className="App-section">
               
                This is section 3
                <p>Open a PDF file <a href={pdfFile} >example</a>.</p>
            </section>
*/
export default App;
